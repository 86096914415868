import React from "react"
import './App.css';
import Routes from "./Routes";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Routes {...this.props}/>
                <div id={'confirm-dialog'}></div>

            </div>
        );
    }
}

export default App
