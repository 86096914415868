import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {notification, CountryCodeComponent} from "../../components/_utils";
import Banner from '../../containers/Layout/images1/signup.jpg'
import {registrationFxn} from "./actions";
import moment from "moment";
import _ from "lodash"
import CountryJson from "../../assets/jsonFiles/countries.json"
import StatesJson from "../../assets/jsonFiles/states.json"
import {InputBox} from "../../components/_utils";

let initState = {
    countryName: undefined,
    stateName: undefined,
    cityName: "",
    companyName: "",
    name: "",
    countryCode: "",
    mobile: "",
    pincode: "",
    email: "",
    address: "",
    certificate: "",
    referralBy: ""
}
const Registration = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(initState);
    let [updateKey, setUpdateKey] = useState(moment())
    let [allCountries, setAllCountries] = useState(CountryJson)
    let [allStates, setAllStates] = useState([])
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadState: countryId => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, item => {
                    return item.country_id == countryId
                })
                return filterArr
            } else {
                // return StatesJson
            }
        },
        chooseCountry: (name) => {
            let countryName = _.find(allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                events._updateState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: '',
                    cityName: ''
                })
                setAllStates(countryName.id ? events.loadState(countryName.id) : [])
            }
        },
        submitHandle: async (e) => {
            e.preventDefault();
            if (!state.companyName) {
                notification.warning({message: "Enter company name"});
                return;
            }
            if (!state.name) {
                notification.warning({message: "Enter person name"});
                return;
            }
            if (!state.countryName) {
                notification.warning({message: "Choose country"});
                return;
            }
            if (!state.stateName) {
                notification.warning({message: "Choose state"});
                return;
            }
            if (!state.cityName) {
                notification.warning({message: "Enter city"});
                return;
            }
            if (!state.countryCode) {
                notification.warning({message: "Choose country code"});
                return;
            }
            if (!state.mobile) {
                notification.warning({message: "Enter mobile no"});
                return;
            }
            if (!state.email) {
                notification.warning({message: "Enter email"});
                return;
            }
            if (!state.certificate || (state.certificate && !state.certificate.name)) {
                notification.warning({message: "Please choose certificate."});
                return;
            }
            if (!state.address) {
                notification.warning({message: "Enter address"});
                return;
            }
            if (!state.referralBy) {
                notification.warning({message: "Enter referral code"});
                return;
            }

            setIsLoading(true);
            let obj = _.clone(state);
            delete obj['certificate']
            let fd = new FormData();

            fd.append('obj', JSON.stringify(obj))
            fd.append('certificate', state.certificate)

            let resp = await registrationFxn(fd)

            if (resp && resp.success) {
                setUpdateKey(moment())
                setState((prevState) => {
                    return {
                        ...prevState,
                        ...initState
                    }
                })
                setIsLoading(false)
            }
        },
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    return (
        <>
            {isLoading ? <div className={'outerLoader'}>
                <div className={'mainLoader'}>
                    Loading...
                </div>
            </div> : null}
            <div>
                <div className="row-banner">
                    <div className="col-banner1">
                        <h6>Registration</h6>
                    </div>
                    <div className="banner col-banner2">
                        <img src={Banner} alt="Banner"/>
                    </div>
                </div>

                <div className="signup-wrapper">
                    <div className="signup-container">
                        <h1>
                            Registration
                        </h1>


                        <form onSubmit={events.submitHandle}>
                            <div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            title={"Company Name"}
                                            value={state.companyName}
                                            onChange={(e) => {
                                                events._updateState({companyName: e})
                                            }}
                                        />
                                    </div>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            title={"Person Name"}
                                            value={state.name}
                                            onChange={(e) => {
                                                events._updateState({name: e})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            type={"select"}
                                            title={"Country"}
                                            showSearch={true}
                                            keyAccessor={(x) => x.name}
                                            valueAccessor={(x) => x.name}
                                            value={state.countryName}
                                            options={CountryJson}
                                            onChange={(e) => {
                                                events.chooseCountry(e)
                                            }}
                                        />
                                    </div>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            type={"select"}
                                            title={"State"}
                                            showSearch={true}
                                            keyAccessor={(x) => x.name}
                                            valueAccessor={(x) => x.name}
                                            value={state.stateName}
                                            options={allStates}
                                            onChange={(e) => {
                                                events._updateState({stateName: e})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            title={"City"}
                                            value={state.cityName}
                                            onChange={(e) => {
                                                events._updateState({cityName: e})
                                            }}
                                        />
                                    </div>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            title={"Pin/Postal Code"}
                                            value={state.pincode}
                                            onChange={(e) => {
                                                events._updateState({pincode: e})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <CountryCodeComponent
                                                    countryCode={state.countryCode}
                                                    chooseCode={(e) => {
                                                        events._updateState({countryCode: e})
                                                    }}/>
                                            </div>
                                            <div className={'col-md-6'}>
                                                <InputBox
                                                    className={"wrap"}
                                                    required={true}
                                                    title={"Phone no"}
                                                    value={state.mobile}
                                                    onChange={(e) => {
                                                        events._updateState({mobile: e})
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            className={"wrap"}
                                            required={true}
                                            type={'email'}
                                            title={"Email"}
                                            value={state.email}
                                            onChange={(e) => {
                                                events._updateState({email: e})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <InputBox
                                            key={updateKey}
                                            required={true}
                                            title={"Certificate"}
                                            type={'file'}
                                            value={state.certificate}
                                            onChange={(e) => {
                                                if (e && e.length) {
                                                    events._updateState({certificate: e[0]})
                                                }
                                            }}
                                        />
                                    </div>
                                    <InputBox
                                        className={"col-md-6 "}
                                        required={true}
                                        type={'text'}
                                        title={"Referral Code"}
                                        value={state.referralBy}
                                        onChange={(e) => {
                                            events._updateState({referralBy: e})
                                        }}
                                    />
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <InputBox
                                            required={true}
                                            title={"Address"}
                                            type={'textarea'}
                                            value={state.address}
                                            onChange={(e) => {
                                                events._updateState({address: e})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                   <br />
                            <hr />
                            <div className="form-group form-wrapper mb-0 mt-10">
                                <button
                                    type="submit"
                                    className="btn-register"
                                    data-loading-text="Please wait..."
                                    
                                >
                                    Register Now
                                </button>
                            </div>
                        </form>
               <br />

                    </div>
                </div>
            </div>
        </>
    );
};

export default Registration;
