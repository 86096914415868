import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import notification from "../../components/Elements/Notification";
import Image from "../../components/image";
import axios from "axios";
import {apiUrl} from "../../settings";

const Login = (props) => {
    const navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });


    const events = {
        submitHandle: async (e) => {
            e.preventDefault();

            if (!formData.email || formData.password.length < 6) {
                notification.warning({message: "Invalid Email or Password"});
                return;
            }
            setIsLoading(true)
            try {
                const api_Url = `${apiUrl}/api/fcmt_login`;
                const {data: response} = await axios.post(api_Url, {
                    email: formData.email,
                    password: formData.password,
                });
                const {data, success, message} = response;
                if (success) {
                    localStorage.setItem("userProfile", JSON.stringify(data));
                    setIsLoading(false)
                    navigate("/profile");
                } else {
                    setIsLoading(false)
                    notification.warning({message: message});
                }
            } catch (error) {
                // console.error("Error during login:", error);
                setIsLoading(false)

                notification.error({message: "An error occurred during login"});
            }
        },
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    return (
        <>
            {isLoading ? <div className={'outerLoader'}>
                <div className={'mainLoader'}>
                    Loading...
                </div>
            </div> : null}
            <div className="section-wrap">
                <div className="main-container">
                    <div className="col-10"></div>
                    <div className="col-60">
                        <div className="full-width-image-about">
                            <Image src="/assets/images/sliderright.png" alt="img"/>
                        </div>
                    </div>
                    <div className="col-40">
                        <div className="login-container">
                            <h3 className="m-0 mb-30">
                                <span>Login</span>
                            </h3>
                            {/* <form
                                onSubmit={handleLogin}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-10">
                                            <input
                                                name="email"
                                                className="form-control"
                                                type="email"
                                                required=""
                                                placeholder="Email"
                                                aria-required="true"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group mb-10">
                                            <input
                                                name="password"
                                                className="form-control required"
                                                required=""
                                                type="password"
                                                placeholder="Password"
                                                aria-required="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0 mt-10">
                                    <button
                                        type="submit"
                                        className="btn btn-gray btn-flat"
                                        data-loading-text="Please wait..."
                                    >
                                        Login
                                    </button>
                                </div>
                            </form> */}

                            <form onSubmit={events.submitHandle}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-10">
                                            <input
                                                name="email"
                                                className="form-control"
                                                type="email"
                                                required=""
                                                placeholder="Email"
                                                aria-required="true"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group mb-10">
                                            <input
                                                name="password"
                                                className="form-control required"
                                                required=""
                                                type="password"
                                                placeholder="Password"
                                                aria-required="true"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0 mt-10">
                                    <button
                                        type="submit"
                                        className="btn-gray btn-flat"
                                        data-loading-text="Please wait..."
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
