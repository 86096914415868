import {loadStripe} from "@stripe/stripe-js";

export {default as notification} from './Elements/Notification'
export {default as DatePicker} from "./Elements/DatePicker";
export {default as InputBox} from "./Elements/InputBox";
export {default as Image} from "./Elements/Image";
export {default as CountryCodeComponent} from "./Elements/CountryCodeComponent";
export {SelectRc as Select, SelectComponent2 as Select2} from "./Elements/Select";

export const fcmtUniversity = '6572f5f243aa131f8cc58616'

export const Years = [
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
]
export const Months = [
    {label: "01", value: 1},
    {label: "02", value: 2},
    {label: "03", value: 3},
    {label: "04", value: 4},
    {label: "05", value: 5},
    {label: "06", value: 6},
    {label: "07", value: 7},
    {label: "08", value: 8},
    {label: "09", value: 9},
    {label: "10", value: 10},
    {label: "11", value: 11},
    {label: "12", value: 12},
]

// export const StripePromise = loadStripe('pk_test_51LNk0qDjoY4Ku4o3eQgP9CBZUXridcq8ntpZAPEfsH4kU8zavj9vDhf3Nbt3hC01PTtQBhtMVNkBiblck3cGXiHj00BduHSGGo');
export const StripePromise = loadStripe('pk_live_51LNk0qDjoY4Ku4o3fSgqJIgYC8Kq5Ob9RPmAAG1BIj7EfIT3cMT7UyEu5E79p5R5lIil3PQbXyHpsSI4RaPzYaMZ00Pi9bNOcf');
export const DocumentTypes = {
    list1: [
        {name: 'Passport', required: true},
        {name: 'IELTS', required: true, restrict: true},
        {name: '10th', required: true, restrict: true},
        {name: '12th', required: true, restrict: true}
    ],
    list2: [
        {name: 'Study Permit', required: true, restrict: true},
        {name: 'Degree', required: false},
        {name: 'Experience', required: false},
        {name: 'Diploma', required: false},

    ],
}
export const dateOfBirthPicker = (data) => {
    data = data.replace("/", "").replace(/[^\d]/, "");
    let day = data.substring(0, 2);
    let month = data.substring(2, 4);
    let year = data.substring(4, 8);
    let date = "";
    if (day > 31) {
        day = "0" + day;
    }
    if (month > 12) {
        month = "0" + month;
    }
    /*if (day) {
        date = date + day;
    }*/
    if (month) {
        month = "/" + month;
    }
    if (year) {
        year = "/" + year;
    }
    return `${day}${month}${year}`;
};
