import React, {Suspense} from 'react'
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import MainLayout from "./containers/Layout/index";
import Login from "./containers/login"
import Profile from "./containers/profile"
import Registration from './containers/signup/registration';
import Application from './containers/signup/application';


const RouteList = (props) => {
    return (
        <Router>
            <Routes>
                <Route path={"/"} element={<MainLayout {...props} />}>
                    <Route index element={<Registration />} 
                    />
                    <Route path="/login" element={<Login {...props} />}/>
                    <Route path="/profile" element={<Profile {...props} />}/>
                    <Route path="/apply" element={<Application/>}/>
                    <Route path="/registration" element={<Registration {...props} />}/>
                 </Route>
            </Routes>
        </Router>
    )
}

export default RouteList
