import {customAxios as axios} from "../../../request";
import {
    contactUsUrl,
    registrationUrl,
    getCourseUrl,
    loadCourseIntakesUrl,
    courseCampusUrl,
    checkBranchUserExistsUrl,
    addStudentUrl, checkReferralCodeUrl
} from "../apis";
import {notification} from "../../../components/_utils";

export const registrationFxn = async (valData) => {
    let {data} = await axios.post(registrationUrl(), valData)
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const contactUsFxn = async (valData) => {
    let {data} = await axios.post(contactUsUrl(), valData)
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const getCourse = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(getCourseUrl(), config)
    return data.data
}
export const loadCourseIntakesFxn = async (valData) => {
    let {data} = await axios.post(loadCourseIntakesUrl(), valData)
    return data
}

export const listCourseCampus = async (filters) => {
    let {data} = await axios.post(courseCampusUrl(), filters)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data

}
export const checkBranchUserExists = async (valData) => {
    let {data} = await axios.post(checkBranchUserExistsUrl(), valData)
    return data
}

export const checkReferralCodeFxn = async (valData) => {
    let {data} = await axios.post(checkReferralCodeUrl(), valData)
    return data
}

export const registerNewStudent = async (valData) => {
    let {data} = await axios.post(addStudentUrl(), valData, {
        'headers': {
            'Access-Control-Allow-Origin': '*',
            'Authorization': ''
        }
    })
    /*  if (!data.error) {
          notification.success({
              message: data.message || 'Success'
          })
      } else {
          notification.error({
              message: data.message || 'Error'
          })
      }*/
    return data
}
