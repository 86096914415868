import React from "react"

const ImageComponent = (props) => {
    let {src, height = 'auto', width = 'auto'} = props;
    return (
        <>
            <img src={src} height={height} width={width}/>
        </>
    )
}
export default ImageComponent
