import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import Image from "../../components/image";
import fullLogo from "./images1/logo-wide-2.png";

import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaRegEnvelope,
  FaTwitter,
  FaWeight,
  FaUser,
  FaSignOutAlt
} from "react-icons/fa";
import {MdKeyboardArrowRight} from "react-icons/md";
import {RiArrowDropDownLine} from "react-icons/ri";

const menuList = [
  {name: "Home", link: "https://fcmtcollege.com/index.html", icon: null},
  {
    name: "About Us",
    link: "/",
    icon: RiArrowDropDownLine,
    submenus: [
      {
        name: "About FCMT",
        link: "https://fcmtcollege.com/about-flair-college.php",
        icon: null,
      },
      {
        name: "Vision and Mission",
        link: "https://fcmtcollege.com/vision-and-mission.php",
        icon: null,
      },
      {name: "KPI 2021", link: "/about/kpi-2021", icon: null},
      {
        name: "Sexual Assault and Sexual Violence Policy 2022",
        link: "https://fcmtcollege.com/sexual-assault-and-sexual-violence-policy-2022.pdf",
        icon: null,
      },
    ],
  },
  {
    name: "Admissions",
    link: "/",
    icon: RiArrowDropDownLine,
    submenus: [
      {
        name: "Domestic Students - Application Form",
        link: "https://fcmtcollege.com/online-application-form.php",
        icon: null,
      },
      {
        name: "International Students - Application Form",
        link: "https://fcmtcollege.com/international-students-application.php",
        icon: null,
      },
    ],
  },
  {
    name: "Programs",
    link: "/",
    icon: RiArrowDropDownLine,
    submenus: [
      {
        name: "Culinary",
        link: "https://fcmtcollege.com/culinary-program.php",
        icon: MdKeyboardArrowRight,
        nestedSubmenus: [
          {
            name: "Cook Basic - Level 1",
            link: "https://fcmtcollege.com/cook-basic-level-1-program.php",
            icon: null,
          },
          {
            name: "Cook Basic - Level 2",
            link: "https://fcmtcollege.com/cook-basic-level-2-program.php",
            icon: null,
          },
          {
            name: "Chef De Cuisine [Diploma]",
            link: "https://fcmtcollege.com/chef-de-cuisine-diploma.php",
            icon: null,
          },
          {
            name: "Diploma in Hospitality Administration",
            link: "https://fcmtcollege.com/diploma-in-hospitality-administration.php",
            icon: null,
          },
          {
            name: "Personal Chef Diploma",
            link: "https://fcmtcollege.com/personal-chef-diploma.php",
            icon: null,
          },
          {
            name: "Diploma in Culinary and Hospitality Operations Management",
            link: "https://fcmtcollege.com/diploma-in-culinary-and-hospitality-operations-management.php",
            icon: null,
          },
        ],
      },
      {
        name: "Business",
        link: "https://fcmtcollege.com/business-program.php",
        icon: MdKeyboardArrowRight,
        nestedSubmenus: [
          {
            name: "Diploma in Business Administration",
            link: "https://fcmtcollege.com/diploma-in-business-administration.php",
            icon: null,
          },
          {
            name: "Diploma in Business Management",
            link: "https://fcmtcollege.com/diploma-in-business-management.php",
            icon: null,
          },
          {
            name: "PG Diploma in Project Management",
            link: "https://fcmtcollege.com/pg-diploma-in-project-management.php",
            icon: null,
          },
          {
            name: "Certificate in Office Administration",
            link: "https://fcmtcollege.com/diploma-in-office-administration.php",
            icon: null,
          },
        ],
      },
      {
        name: "Technology",
        link: "https://fcmtcollege.com/technology-program.php/programs/technology",
        icon: MdKeyboardArrowRight,
        nestedSubmenus: [
          {
            name: "Diploma in Computing Support",
            link: "https://fcmtcollege.com/diploma-in-computing-support.php",
            icon: null,
          },
          {
            name: "Postgraduate Diploma in Enterprise Network Engineering",
            link: "https://fcmtcollege.com/pg-diploma-in-enterprise-network-engineering.php",
            icon: null,
          },
          {
            name: "Postgraduate Diploma in Systems Testing Engineering",
            link: "https://fcmtcollege.com/pg-diploma-in-system-testing-engineering.php",
            icon: null,
          },
        ],
      },
    ],
  },
  {name: "Blogs", link: "https://fcmtcollege.com/blog.php", icon: null},
  {
    name: "Contact Us",
    link: "https://fcmtcollege.com/contactus.php",
    icon: null,
  },
  {
    name: "Registration",
    link: "/registration",
    icon: null,
  },
  {
    name: "Apply",
    link: "/apply",
    icon: null
  }
  // {name: "Registration", link: "/registration"},
];

const Header = (props) => {
  const [isSticky, setIsSticky] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuHover = (index) => {
    setHoveredMenu(index);
  };

  const handleMenuLeave = () => {
    setHoveredMenu(null);
    setActiveSubmenu(null);
  };

  const handleSubMenuClick = (submenuIndex) => {
    setActiveSubmenu(submenuIndex);
  };

  const handleSubMenuHover = (index) => {
    setActiveSubmenu(index);
  };

  // const handleSubMenuLeave = () => {
  //   setTimeout(() => {
  //     setActiveSubmenu(null);
  //   }, 300);
  // };

  const handleMenuClick = (link, index) => {
    if (link.startsWith("http")) {
      window.location.href = link;
    } else {
      setActiveMenu(index);
      navigate(link);
    }
  };


  const handleLogout = () => {
    localStorage.removeItem('userProfile');
    navigate("/");
  };

  const handleProfile = () => {
    navigate("/profile")
  }

  return (
      <>
        <div
            className={`above-navbar header-top bg-theme-colored2 sm-text-center ${
                isSticky ? "sticky" : ""
            }`}
        >
          <div className="header-container">
            {/* Contact Info */}
            <div>
              <ul className="contact-info-list">
                <li>
                  <FaPhoneAlt/>
                  <span className="icon-wrap">1844 400 3268</span>
                </li>
                <li>
                  <FaRegEnvelope/>
                  <span className="icon-wrap">info@fcmtcollege.com</span>
                </li>
              </ul>
            </div>

            <div>
              <ul className="contact-info-list social-icons">
                <li style={{margin: "0 5px"}}>
                  <a
                      href="https://www.facebook.com/FCMTCollege"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                  >
                    <FaFacebookF/>
                  </a>
                </li>
                <li style={{margin: "0 5px"}}>
                  <a
                      href="https://twitter.com/fcmtcollege"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                  >
                    <FaTwitter/>
                  </a>
                </li>
                <li style={{margin: "0 5px"}}>
                  <a
                      href="https://www.instagram.com/FCMTCollege/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                  >
                    <FaInstagram/>
                  </a>
                </li>
                <li style={{margin: "0 5px"}}>
                <a
                  href={userProfile ? "/profile" : "/login"}  
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaUser style={{margin: "0 5px"}}/> 
                 <span className="username">{userProfile && userProfile.name && userProfile.name.toUpperCase()}</span> 
                </a>
                 <a>
                 {userProfile && (
                  <FaSignOutAlt onClick={handleLogout} style={{margin: "0 0 0 24px"}}  className="social-link"/>
                
                   )}
                 </a>
               
              </li>
              </ul>
            </div>
          </div>
        </div>

        <div
            data-animation="default"
            className={`nav w-nav ${isSticky ? "sticky" : ""}`}
            data-easing2="ease"
            data-easing="ease"
            data-collapse="medium"
            data-w-id="3bf88711-82b8-6a64-ba60-30f4622e74b4"
            role="banner"
            data-duration="400"
            data-doc-height="1"
        >
          <div className="navbarcontainer w-container">
            <div className="navbar-content">
              <div className="navbar-brand">
                <a
                    href="/"
                    aria-current="page"
                    className="w-inline-block w--current"
                >
                  <img
                      src={fullLogo}
                      loading="lazy"
                      alt=""
                      className="logo"
                  />
                </a>
              </div>
              <nav role="navigation" className="navbar-menu w-nav-menu">
              {menuList.map((item, index) => (
                <div
                  key={`top-${item.name}`}
                  className={`navbar-link w-nav-link ${
                    hoveredMenu === index || activeMenu === index ? "active" : "" 
                  }`}
                  onMouseEnter={() => handleMenuHover(index)}
                  onMouseLeave={handleMenuLeave}
                  onClick={() => handleMenuClick(item.link, index)}
                >
                      <div
                          className="nav-text"
                          onClick={() => handleMenuClick(item.link)}
                      >
                        {item.name}
                        {item.icon && (
                            <item.icon size={24} style={{fontWeight: 100, marginLeft : '-5px'}}/>
                        )}
                      </div>
                      {item.submenus && hoveredMenu === index && (
                          <div className="submenu-container">
                            {item.submenus.map((submenu, submenuIndex) => (
                                <div
                                    key={`submenu-${submenuIndex}`}
                                    className={`submenu-link ${
                                        activeSubmenu === submenuIndex ? "active" : ""
                                    }`}
                                    onMouseEnter={() => handleSubMenuHover(submenuIndex)}
                                >
                                  <a href={submenu.link}>
                                    {submenu.name}
                                    {submenu.icon && (
                                        <submenu.icon
                                            size={20}
                                            className="custom-submenu-icon"
                                        />
                                    )}
                                  </a>
                                  {submenu.nestedSubmenus &&
                                  activeSubmenu === submenuIndex && (
                                      <div className="nested-submenu-container">
                                        {submenu.nestedSubmenus.map(
                                            (nestedSubmenu, nestedSubmenuIndex) => (
                                                <a
                                                    key={`nested-submenu-${nestedSubmenuIndex}`}
                                                    href={nestedSubmenu.link}
                                                    className="nested-submenu-link"
                                                >
                                                  {nestedSubmenu.name}
                                                  {nestedSubmenu.icon && (
                                                      <nestedSubmenu.icon size={15}/>
                                                  )}
                                                </a>
                                            )
                                        )}
                                      </div>
                                  )}
                                </div>
                            ))}
                          </div>
                      )}
                    </div>
                ))}
              
              </nav>
            </div>
            <div className="menu-button w-nav-button">
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0">
          <nav
              role="navigation"
              className="navbar-menu w-nav-menu"
              data-nav-menu-open=""
          >
            {menuList.map((item, index) => {
              return (
                  <>
                    {index < menuList.length - 1 ? (
                        <a
                            href={item.link}
                            key={`top-${item.name}`}
                            style={{maxWidth: 1200}}
                            className="navbar-link w-nav-link w--nav-link-open"
                        >
                          <div className="nav-text">{item.name}</div>
                        </a>
                    ) : (
                        <>
                          {/*<a href={item.link} key={`top-${item.name}`} style={{maxWidth: 1200}}
                                              className="navbar-button w-nav-link w--nav-link-open">
                                            <div className="btn-text primary">
                                                {item.name}
                                            </div>
                                        </a>*/}
                          <a
                              href={item.link}
                              key={`top-${item.name}`}
                              style={{maxWidth: 1200}}
                              className="navbar-link w-nav-link w--nav-link-open"
                          >
                            <div className="btn-text primary">{item.name}</div>
                          </a>
                        </>
                    )}
                  </>
              );
            })}

            {/*   <a href="/about"
                       className="navbar-link w-nav-link w--nav-link-open"
                       style={{maxWidth: 1200}}>
                        <div className="nav-text">About</div>
                    </a><a href="/admissions" className="navbar-link w-nav-link w--nav-link-open"
                           style={{maxWidth: 1200}}>
                    <div className="nav-text">Admissions</div>
                </a><a href="/programs" className="navbar-link w-nav-link w--nav-link-open" style={{maxWidth: 1200}}>
                    <div className="nav-text">Programs</div>
                </a><a href="#contact" className="navbar-button w-nav-link w--nav-link-open" style={{maxWidth: 1200}}>
                    <div className="btn-text primary">Contact</div>
                </a>*/}
          </nav>
        </div>
      </>
  );
};
export default Header;
