import React from "react"
import whiteLogo from "./images1/footer2.png"
import fbImage from "./images1/fb_Vectors-Wrapper.svg"
import instImage from "./images1/insta_Vectors-Wrapper.svg"

import locationIcon from "./images1/location_Vectors-Wrapper.svg"
import phoneIcon from "./images1/phone_Vectors-Wrapper.svg"
import mailIcon from "./images1/mail_Vectors-Wrapper.svg"
import Image from "../../components/image"

const ScriptComponent = React.lazy(() => import("./scriptComponent"));
const FooterComponent = (props) => {
    return (
        <>
            <div className="footer">
                <div className="columns-3">
                    <div className="column-5">
                        <div className="column-6">
                            <div className="column-7">
                                <div className="logo-wrapper">
                                    <div className="logo-2">
                                        <Image src={whiteLogo}/>
                                    </div>
                                </div>
                            </div>
                            <div className="column-8">
                                <div className="content-5"><a href="/" className="link w-inline-block">
                                    <div className="text-16">About</div>
                                </a><a href="/" className="link w-inline-block">
                                    <div className="text-16">Admissions</div>
                                </a><a href="/" className="link w-inline-block">
                                    <div className="text-16">Programs</div>
                                </a></div>
                            </div>
                        </div>
                        <div className="column-9">
                            <div className="column-10">
                                <div className="frame-8"><Image
                                    src={locationIcon}
                                    loading="lazy" width="14" height="14" alt="" className="vectors-wrapper-3"/><a
                                    href="https://goo.gl/maps/EupdbfxxKuvT3H63A" target="_blank"
                                    className="map-link w-inline-block">
                                    <div className="text-17">7601 Jane Street, Concord L4K 1X2</div>
                                </a></div>
                                <div className="frame-8"><Image
                                    src={phoneIcon}
                                    loading="lazy" width="14" height="14" alt="" className="vectors-wrapper-3"/><a
                                    href="tel:+1604-353-4034" className="link-phone w-inline-block">
                                    <div className="text-17">1844 400 3268</div>
                                </a></div>
                                <div className="frame-8"><Image
                                    src={mailIcon}
                                    loading="lazy" width="14" height="14" alt="" className="vectors-wrapper-3"/>
                                    <div className="frame-8"><a href="/"
                                                                className="link-email w-inline-block">
                                        <div className="text-17">info@fcmtcollege.com</div>
                                    </a><a href="/"
                                           className="link-email w-inline-block">
                                        <div className="text-17"></div>
                                    </a></div>
                                </div>
                                <div className="frame-8">
                                    <div className="frame-8"><a
                                        className="link-email w-inline-block">
                                        <div className="text-17"></div>
                                    </a></div>
                                </div>
                            </div>

                            <div className="column-11">
                                <a href="https://www.facebook.com/FCMTCollege"
                                   className="w-inline-block"><Image
                                    src={fbImage}
                                    loading="lazy" width="40" height="40" alt="" className="vectors-wrapper-4"/></a><a
                                href="https://www.instagram.com/FCMTCollege/" className="w-inline-block"><Image
                                src={instImage}
                                loading="lazy" width="40" height="40" alt="" className="vectors-wrapper-4"/></a></div>
                        </div>
                    </div>

                </div>
                <div className="copyright">
                    <div className="text-19">Copyright ©2021 FCMT. All Rights Reserved</div>
                </div>
            </div>


            <script type="text/javascript"
                    src={"https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"}></script>
            <script type="text/javascript" src={"../assets/scripts/webflow.js"}></script>
            <script type="text/javascript" src={"../assets/scripts/custom-script.js"}></script>
        </>
    )
}
export default FooterComponent
