import React, {Component} from 'react'
import {DatePicker, Select} from "../../_utils";

const TextAreaComponent = (props) => {
    let {type, onChange = null, title, placeholder = null, value} = props;
    return (
        <>
      <textarea
          className="form-control"
          style={{width: "100%"}}
          type={type}
          rows={3}
          value={value}
          onChange={(e) => {
              onChange(e.target.value);
          }}
          placeholder={placeholder ? placeholder : `Enter ${title.toLowerCase()}`}
      />
        </>
    );
};
const InputFile = (props) => {
    let {
        type,
        onChange = null,
        title,
        placeholder = null,
        disabled = false,
    } = props;
    return (
        <>
            <input
                className="form-control"
                type={type}
                disabled={disabled}
                onChange={(e) => {
                    onChange(e.target.files)
                }}
                placeholder={placeholder ? placeholder : `Enter ${title.toLowerCase()}`}
            />
        </>
    );
};
const InputText = (props) => {
    let {
        type = 'text',
        onChange = null,
        title,
        placeholder = null,
        disabled = false,
        value = null,
    } = props;
    return (
        <>
            <input
                className="form-control"
                type={type}
                value={value}
                disabled={disabled}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                placeholder={placeholder ? placeholder : `Enter ${title.toLowerCase()}`}
            />
        </>
    );
};

class InputBoxComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let {title = '', className = '', extra, labelCls = '', customStyle = {}} = this.props
        return (
            <div className={'inputBox'} style={customStyle}>
                <div className={'labelRow'}>
                    {title ? <React.Fragment>
                        <label className={`labelNew ${labelCls}`}>{title} : </label>
                        {extra ? <div className={'extra'}>{extra}</div> : ''}
                    </React.Fragment> : ''}
                </div>
                <div>
                    <div className={`${className} innerBox rowFlex`}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

class InputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    RenderField = (type) => {
        if (type == "select") {
            return <Select {...this.props} />;
        } else if (type == "textarea") {
            return <TextAreaComponent {...this.props} />;
        } else if (type == "file") {
            return <InputFile {...this.props} />;
        } else if (type == "date") {
            return <DatePicker {...this.props} />;
        } else if (type == "custom") {
            return this.props.children;
        } else {
            return <InputText {...this.props} />;
        }
    };

    render() {
        let {
            title = "",
            className = "",
            extra = "",
            labelCls = "",
            required = false,
            type = "text",
            showLabel = true,
        } = this.props;
        return (
            <>
                <div className={`${className}`}>
                    {showLabel && (
                        <div style={{display: "flex"}}>
                            <label className="required form-label" style={{marginRight: 10}}>
                                {required && "*"} {title}:
                            </label>
                            {extra}
                        </div>
                    )}
                    {this.RenderField(type)}
                </div>
            </>
        );
    }
}


export default InputComponent

