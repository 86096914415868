import React, {useEffect, useState} from 'react';
import Image from '../../components/image';
import {FaDownload} from "react-icons/fa";

const Profile = () => {
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        const storedProfile = localStorage.getItem('userProfile');

        if (storedProfile) {
            setUserProfile(JSON.parse(storedProfile));
        }
    }, []);


    if (!userProfile) {
        return <div>Loading...</div>;
    }
    const handleDownload = (fileUrl, fileName) => {
        /*const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);*/
        window.open(fileUrl, '_blank')
    };
//   const formData = [
//     { label: 'Student Name', value: userProfile.name },
//     { label: 'Date of Birth', value: new Date(userProfile.dateOfBirth).toISOString().split('T')[0] },
//     { label: 'Phone No', value: userProfile.mobile },
//     { label: 'Address', value: userProfile.address },
//     { label: 'Gender', value: userProfile.gender },
//     { label: 'University', value: userProfile.application.courseUniversity.universityName },
//     { label: 'College', value: userProfile.application.courseName },
//     { label: 'Campus', value: userProfile.application.status === 'Visa Approved' ? userProfile.application.campusId.campusName : '' },
//     { label: 'Intake', value: `${userProfile.application.intake.month} ${userProfile.application.intake.year}` },
//   ];

    const documentData = [
        {
            label: 'LOA Letter',
            path: userProfile.application.loaLetter.path,
            fileName: userProfile.application.loaLetter.fileName
        },
        {
            label: 'Enrollment Letter',
            path: userProfile.application.enrollmentLetter.path,
            fileName: userProfile.application.enrollmentLetter.fileName
        },
        {
            label: 'Academic Schedule',
            path: userProfile.application.academicSchedule?.path,
            fileName: userProfile.application.academicSchedule?.fileName || 'Academic Schedule'
        },
    ];
    const formattedDateOfBirth = new Date(userProfile.dateOfBirth).toISOString().split('T')[0];
    return (
        <>
            <div>
                <div className="container-fluid profile_container">
                    <h1>Welcome {userProfile.name}</h1>
                </div>
                <div className='student-container'>
                    <div className="student-data">
                        <div className="left-section">
                            <h3 className='student-text'>STUDENT PROFILE</h3>

                            <form>
                                <div className="form-row">
                                    <div className='form-field'>
                                        <label htmlFor="studentName">Student Name</label>
                                        <input
                                            type="text"
                                            id="studentName"
                                            value={userProfile.name || 'null'}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="dob">Date of Birth</label>
                                        <input
                                            type="text"
                                            id="dob"
                                            value={formattedDateOfBirth || 'null'}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="phone">Phone No</label>
                                        <input
                                            type="text"
                                            id="phone"
                                            value={userProfile.mobile || 'null'}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className='form-field'>
                                        <label htmlFor="address">Address</label>
                                        <input
                                            type="text"
                                            id="address"
                                            value={userProfile.address || 'null'}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="gender">Gender</label>
                                        <input
                                            type="text"
                                            id="gender"
                                            value={userProfile.gender || 'null'}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="university">University</label>
                                        <input
                                            type="text"
                                            id="university"
                                            value={userProfile.application.courseUniversity.universityName || 'null'}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className='form-field'>
                                        <label htmlFor="college">College</label>
                                        <input
                                            type="text"
                                            id="college"
                                            value={userProfile.application.courseName || 'null'}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="campus">Campus</label>
                                        <input
                                            type="text"
                                            id="campus"
                                            value={userProfile.application.status === 'Visa Approved' ? userProfile.application.campusId.campusName : ''}
                                            readOnly
                                        />
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor="intake">Intake</label>
                                        <input
                                            type="text"
                                            id="intake"
                                            value={`${userProfile.application.intake.month || 'null'} ${userProfile.application.intake.year || 'null'}`}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="right-section">
                            <div className='download-section'>
                                <h6>Documents</h6>
                                {documentData.map((document, index) => (
                                    <a className='download-box' key={index}
                                       onClick={() => handleDownload(document.path, document.fileName)}>
                                        <input type='text' value={document.label} readOnly className='form-data'/>
                                        <FaDownload style={{color: 'white'}} className='icon-download'/>
                                    </a>
                                ))}
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default Profile;
