import React from "react"
import HeaderComponent from "./header";
import FooterComponent from "./footer";
import {Outlet, Navigate} from "react-router-dom";

const Layout = (props) => {
    return (
        <>
            <div className={'body'}>
                <HeaderComponent/>
                <Outlet/>
                <FooterComponent/>
            </div>


        </>
    )
}
export default Layout
