import {apiUrl} from "../../../settings";

export const registrationUrl = () => {
    return apiUrl + '/fcmt/registration'
}

export const contactUsUrl = () => {
    return apiUrl + '/fcmt/contactUs'
}

export const getCourseUrl = () => {
    return apiUrl + '/courseWithoutAuth'
}
export const loadCourseIntakesUrl = () => {
    return apiUrl + '/api/loadCourseIntakes'
}
export const courseCampusUrl = () => {
    return apiUrl + '/api/courseCampus'
}
export const checkBranchUserExistsUrl = () => {
    return apiUrl + '/api/checkBranchUserExists'
}
export const addStudentUrl = () => {
    return apiUrl + '/fcmt-onshore/add-student'
}

export const checkReferralCodeUrl = () => {
    return apiUrl + '/fcmt/checkReferralCode'
}
