import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import './style.css'
import Banner from '../../containers/Layout/images1/signup.jpg'
import {InputBox, DocumentTypes, fcmtUniversity, dateOfBirthPicker} from "../../components/_utils";
import CountryJson from "../../assets/jsonFiles/countries.json"
import StatesJson from "../../assets/jsonFiles/states.json"
import _ from "lodash";
import {notification} from "../../components/_utils";
import {
    getCourse,
    listCourseCampus,
    loadCourseIntakesFxn,
    checkBranchUserExists,
    checkReferralCodeFxn,
    registerNewStudent
} from "../signup/actions/index";

let initState = {
    name: "",
    familyName: "",
    dateOfBirth: "",
    dateOfBirthString: "",
    issueDate: "",
    mobile: "",
    address: "",
    countryName: "",
    stateName: "",
    cityName: "",
    pincode: "",
    email: "",
    intake: {},
    postalCode: "",
    country: {},
    state: {},
    city: "",
    intakeTemp: "",
    studentName: "",
    phoneNo: "",
    course: {},
    campus: {},
    referralCode: "",
    passport: null,
    ilets: null,
    matrix: null,
    secondary: null,
    studyPermit: null,
    degree: null,
    experience: null,
    diploma: null
}
const Application = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        studentName: "",
        familyName: "",
        dateOfBirth: null,
        dateOfBirthString: null,
        issueDate: null,
        phoneNo: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pinCode: "",
        postalCode: "",
        email: "",
        course: "",
        campus: "",
        intake: "",
        referralCode: "",
        passport: undefined,
        ilets: undefined,
        matrix: undefined,
        secondary: undefined,
        studyPermit: undefined,
        degree: undefined,
        experience: undefined,
        diploma: undefined,

    });
    const [countries, setCountries] = useState([]);
    let [allCountries, setAllCountries] = useState(CountryJson)
    let [state, setState] = useState(initState)
    let [courseList, setCourseList] = useState([])
    let [intakesList, setIntakesList] = useState([])
    let [campusList, setCampusList] = useState([])
    let [application, setApplication] = useState({})
    let [allStates, setAllStates] = useState([])
    let [pageLoading, setPageLoading] = useState(false)
    let [updateKey, setUpdateKey] = useState(moment())
    let uniEvents = {
        getCourseByUniversity: async () => {
            let {data} = await getCourse({
                results: 1000000, courseUniversity: fcmtUniversity, sortField: 'courseName',
                sortOrder: 'ascend', select: ['courseName', 'tuitionFee']
            })
            setCourseList(data)
        },
        loadCourseIntakesFxn: async (courseId) => {
            let resp = await loadCourseIntakesFxn({courseId})
            let data = []
            _.each(resp, (item) => {
                if (item) {
                    item.value = `${item.intake}-${item.year}`
                    item.label = `${item.intake}, ${item.year}`
                    data.push(item)
                }
            })
            setIntakesList(data)
        },
        loadCampus: async (courseId) => {
            let obj = {
                results: 1000000,
                courseId: courseId
            }
            let {data} = await listCourseCampus(obj)
            setCampusList(data)
        },
        chooseCourseFxn: async (selectedCourseId) => {
            let selectedCourse = _.find(courseList, (item) => {
                return item._id.toString() == selectedCourseId
            })
            if (selectedCourse) {
                let valData = {}
                valData.applicationFee = selectedCourse.courseUniversity.applicationFee
                valData.courseUniversity = selectedCourse.courseUniversity._id
                valData.universityCountry = selectedCourse.universityCountry._id
                valData.universityCity = selectedCourse.universityCity
                valData.universityState = selectedCourse.universityState
                valData.tuitionFee = selectedCourse.tuitionFee
                valData.courseId = selectedCourse._id
                valData._id = selectedCourse._id
                valData.courseName = selectedCourse.courseName

                let {success, message} = await checkBranchUserExists(valData)
                if (success) {
                    setApplication(valData)
                    events._updateState({courseId: selectedCourse._id})
                    uniEvents.loadCampus(selectedCourse._id)
                    uniEvents.loadCourseIntakesFxn(selectedCourse._id)

                } else {
                    setApplication({})
                    notification.error({message})
                }
            } else {
                setApplication({})
                notification.error({message: 'Course not found.'})
            }
        },
        chooseIntake: (intakeValue) => {
            let intakeVal = intakeValue.split('-');
            let intake = {
                month: intakeVal[0],
                year: intakeVal[1]
            }
            events._updateState({
                intake,
                intakeTemp: intakeValue,
            })
        },
        chooseDocument: (e) => {
            let {name, files} = e
            if (files && files.length) {
                setState({
                    ...state,
                    [name]: files[0]
                })
            }
        },
    }

    useEffect(() => {
        uniEvents.getCourseByUniversity()
    }, [fcmtUniversity])
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadState: countryId => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, item => {
                    return item.country_id == countryId
                })
                return filterArr
            } else {
                // return StatesJson
            }
        },
        chooseCountry: (name) => {
            let countryName = _.find(allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                events._updateState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: '',
                    cityName: ''
                })
                setAllStates(countryName.id ? events.loadState(countryName.id) : [])
            }
        },
        submitHandle: async (e) => {
            e.preventDefault()
            if (!state.name) {
                notification.warning({message: "Enter student name"});
                return;
            }
            if (!state.familyName) {
                notification.warning({message: "Enter family name"});
                return;
            }
            if (!state.dateOfBirthString) {
                Notification.warning({message: "Choose date of birth"});
                return;
            }
            if (!moment(state.dateOfBirthString, "DD/MM/YYYY", true).isValid()) {
                Notification.warning({
                    message: 'Please enter valid date of birth.'
                })
                return
            }

            if (!state.mobile) {
                notification.warning({message: "Enter phone no"});
                return;
            }
            if (!state.address) {
                notification.warning({message: "Enter address"});
                return;
            }
            if (!state.countryName) {
                notification.warning({message: "Choose country"});
                return;
            }
            if (!state.stateName) {
                notification.warning({message: "Choose state"});
                return;
            }
            if (!state.cityName) {
                notification.warning({message: "Enter city"});
                return;
            }
            if (!state.postalCode) {
                notification.warning({message: "Enter Postal/Zip code"});
                return;
            }

            if (!state.email) {
                notification.warning({message: "Enter email"});
                return;
            }

            if (!application || (application && !application.courseId)) {
                notification.warning({
                    message: 'Choose course.'
                })
                return
            }
            if (!state.campusId) {
                notification.warning({
                    message: 'Choose Campus.'
                })
                return
            }
            if (!state.intake || (state.intake && !state.intake.month)) {
                notification.warning({
                    message: 'Choose Intake.'
                })
                return
            }

            if (state.familyName) {
                application.familyName = state.familyName
            }


            if (!state.referralCode) {
                notification.warning({message: "Enter referral code"});
                return;
            }

            if (!state.issueDate) {
                notification.warning({message: "Choose issue date"});
                return;
            }

            let findDoc = _.find([...DocumentTypes.list1, ...DocumentTypes.list2], (item) => {
                let fieldName = item.name
                if (item.required && (!state[fieldName] || (state[fieldName] && !state[fieldName].name))) {
                    return true
                }
            })
            if (findDoc && findDoc.name) {
                notification.warning({
                    message: `Choose ${findDoc.name} document.`
                })
                return
            }
            application.campusId = state.campusId
            application.intake = state.intake
            application.issueDate = state.issueDate

            let obj = {}
            obj.application = [application]
            /*if (state.fileList && state.fileList.length) {
                obj.document = state.fileList
            }*/
            obj = {...obj, ...state}
            /* if (obj.dateOfBirth) {
                 obj.dateOfBirth = momentDateZone(obj.dateOfBirth);
             }*/
            setPageLoading(true)
            checkReferralCodeFxn(obj).then(async (resp) => {
                if (resp && resp.success) {
                    let fd = new FormData()
                    fd.append('obj', JSON.stringify(obj))
                    _.each(DocumentTypes.list1, (item) => {
                        if (state[item.name]) {
                            fd.append([item.name], state[item.name])
                        }
                    })
                    _.each(DocumentTypes.list2, (item) => {
                        if (state[item.name]) {
                            fd.append([item.name], state[item.name])
                        }
                    })
                    let data = await (registerNewStudent(fd))
                    setPageLoading(false);
                    if (data && data.success) {
                        setPageLoading(false);
                        alert(data.message);
                        window.location.reload()
                        setState((prevState) => {
                            return {
                                ...prevState,
                                ...initState
                            }
                        })
                    } else {
                        setPageLoading(false);
                        notification.warning({message: data.message})
                    }
                } else {
                    setPageLoading(false);
                    notification.warning({message: resp.message})
                }
            })
        }
    };


    return (
        <>

            {pageLoading ? <div className={'outerLoader'}>
                <div className={'mainLoader'}>
                    Loading...
                </div>
            </div> : null}
            <div>
                <div className="row-banner">
                    <div className="col-banner1">
                        <h6>Apply For<br/><br/>Application</h6>
                    </div>
                    <div className="banner col-banner2">
                        <img src={Banner} alt="Banner"/>
                    </div>
                </div>

                <div className="signup-wrapper">
                    <div className="signup-container">
                        <h1>
                            Apply For Application
                        </h1>
                        <form onSubmit={events.submitHandle}>
                            <div>
                                <div className={'row'}>

                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Student First Name"}
                                        value={state.name}
                                        onChange={(e) => {
                                            events._updateState({name: e})
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Family Name"}
                                        value={state.familyName}
                                        onChange={(e) => {
                                            events._updateState({familyName: e})
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Date of Birth (DD/MM/YYYY)"}
                                        placeholder={"Date of Birth (DD/MM/YYYY)"}
                                        value={state.dateOfBirthString}
                                        onChange={(e) => {
                                            events._updateState({
                                                dateOfBirthString: dateOfBirthPicker(e)
                                            })
                                        }}/>
                                </div>
                                <div className={'row'}>
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Phone no"}
                                        value={state.mobile}
                                        onChange={(e) => {
                                            events._updateState({mobile: e})
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Address"}
                                        value={state.address}
                                        onChange={(e) => {
                                            events._updateState({address: e})
                                        }}
                                    />


                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={"select"}
                                        title={"Country"}
                                        showSearch={true}
                                        keyAccessor={(x) => x.name}
                                        valueAccessor={(x) => x.name}
                                        value={state.countryName}
                                        options={CountryJson}
                                        onChange={(e) => {
                                            console.log(e)
                                            events.chooseCountry(e)
                                        }}
                                    />

                                </div>
                                <div className={'row'}>
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={"select"}
                                        title={"State"}
                                        showSearch={true}
                                        keyAccessor={(x) => x.name}
                                        valueAccessor={(x) => x.name}
                                        value={state.stateName}
                                        options={allStates}
                                        onChange={(e) => {
                                            events._updateState({stateName: e})
                                        }}
                                    />

                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"City"}
                                        value={state.cityName}
                                        onChange={(e) => {
                                            events._updateState({cityName: e})
                                        }}
                                    />

                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        title={"Postal/Zip code"}
                                        value={state.postalCode}
                                        onChange={(e) => {
                                            events._updateState({postalCode: e})
                                        }}
                                    />


                                </div>
                                <div className={'row'}>

                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={'email'}
                                        title={"Email"}
                                        value={state.email}
                                        onChange={(e) => {
                                            events._updateState({email: e})
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={"select"}
                                        title={"Course"}
                                        showSearch={true}
                                        keyAccessor={(x) => x._id}
                                        valueAccessor={(x) => x.courseName}
                                        value={state.courseId}
                                        options={courseList}
                                        onChange={(e) => {
                                            uniEvents.chooseCourseFxn(e)
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={"select"}
                                        title={"Campus"}
                                        showSearch={true}
                                        keyAccessor={(x) => x._id}
                                        valueAccessor={(x) => x.campusName}
                                        value={state.campusId}
                                        options={campusList}
                                        onChange={(e) => {
                                            events._updateState({campusId: e})
                                        }}
                                    />
                                </div>
                                <div className={'row'}>
                                    <InputBox
                                        className={"col-md-4 "}
                                        required={true}
                                        type={"select"}
                                        title={"Intake"}
                                        showSearch={true}
                                        keyAccessor={(x) => x.value}
                                        valueAccessor={(x) => x.label}
                                        value={state.intakeTemp}
                                        options={intakesList}
                                        onChange={(e) => {
                                            uniEvents.chooseIntake(e)
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={'text'}
                                        title={"Referral Code"}
                                        value={state.referralCode}
                                        onChange={(e) => {
                                            events._updateState({referralCode: e})
                                        }}
                                    />
                                    <InputBox
                                        className={"col-md-4"}
                                        required={true}
                                        type={'date'}
                                        placeholder={'Choose Issue Date'}
                                        title={"Issue Date"}
                                        value={state.issueDate}
                                        onChange={(e) => {
                                            if (e) {
                                                events._updateState({
                                                    issueDate: e
                                                })
                                            }
                                        }}
                                    />

                                </div>
                                <div className={'row'}>
                                    {DocumentTypes.list1.map((item) => {
                                        return (
                                            <InputBox
                                                className={"col-md-3"}
                                                key={item.name}
                                                type={"file"}
                                                required={true}
                                                name={item.name}
                                                id={item.name}
                                                title={item.name}
                                                onChange={(e) => {
                                                    uniEvents.chooseDocument({name: item.name, files: e})
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                                <div className={'row'}>
                                    {DocumentTypes.list2.map((item) => {
                                        return (
                                            <InputBox
                                                className={"col-md-3"}
                                                type={"file"}
                                                key={item.name}
                                                name={item.name}
                                                id={item.name}
                                                title={(item.required ? '*' : '') + ' ' + item.name}
                                                onChange={(e) => {
                                                    uniEvents.chooseDocument({name: item.name, files: e})
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group form-wrapper mb-0 mt-10">
                                <button
                                    type="submit"
                                    className="btn-register"
                                    data-loading-text="Please wait..."

                                >
                                    Apply Now
                                </button>
                            </div>
                        </form>
                        <br/>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Application;
